import React from "@heroicons/react";
import { useContractRead } from "wagmi";
import { StakingContractABI, tokenABIJtt } from "../../constants/abis";
import Icon from "../../components/crypto_icons";
import { Decimals } from "../../constants/decimal";

export default function UnstakedRewardCard(props) {
  const { account, rewardTokenAddr, StakingContractAddr } = props;
  const { data: earnedReward, isLoading: isLoadingEarneddBalance } =
    useContractRead({
      address: StakingContractAddr,
      abi: StakingContractABI,
      functionName: "earned",
      args: [account, rewardTokenAddr],
      watch: true,
    });

    let formatEarned;

    console.log("reward token", rewardTokenAddr)

    if (rewardTokenAddr === "0xf382A28AAb0320e48D3D751f696f935B014B49BD") {
      // Conditionally divide by 1e18 for a specific reward address
      formatEarned = Number(earnedReward) / 100000000;
    } else {
      // Use the raw earnedReward value without division
      formatEarned = Number(earnedReward) / 1000000000000000000;
    }

    const { data: tokenSymbol } = useContractRead({
      watch: true,
      address: rewardTokenAddr,
      abi: tokenABIJtt,
      functionName: "symbol",
    });
  return (
    <div className="bg-[#376eab] mb-5 rounded-xl">
      <div className="flex flex-row items-center  w-[100%] text-center">
        <div className="flex flex-row items-center w-[100%] text-center">
          <Icon symbol={tokenSymbol} />
          <div className="ml-2">
          {isLoadingEarneddBalance ? (
            <div className="h-6 w-12 image-thumbnail rounded-sm bg-secondary animate-pulse flex items-center justify-center"></div>
          ) : (
            <p className="text-lg text-start">
              {isNaN(formatEarned) ? "0" : formatEarned.toFixed(5)} {tokenSymbol}
            </p>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}
