import "@rainbow-me/rainbowkit/styles.css";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import React from "@heroicons/react";
import NavComponent from "./components/navbar";
import { useState } from "react";
import { useEffect } from "react";
import StakeComponent from "./scenes/stake/stake";
import PartnerComponent from "./scenes/partner/partner";
import Dashboard from "./scenes/dashboard/dashboard";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  trustWallet,
  ledgerWallet,
  walletConnectWallet,  
} from '@rainbow-me/rainbowkit/wallets';
import { Buffer } from "buffer";

window.Buffer = window.Buffer || Buffer;
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [publicProvider()]
);

const projectId = "JS";

const connectors = connectorsForWallets([
  {
    groupName: 'Suggested',
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: 'Justus Stake' }),
      trustWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains }), 
      walletConnectWallet({ projectId, chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export default function App() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // Google Analytics
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-HLD6XVPGB0";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-HLD6XVPGB0');
    };
  }, []);

  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider chains={chains}>
        <main className="main">
          <NavComponent setActiveTab={setActiveTab} activeTab={activeTab} />
          <div className="content h-[100%]">
            {activeTab === 0 ? (
              <Dashboard />
            ) : activeTab === 1 ? (
              <StakeComponent />
            ) : (
              <PartnerComponent />
            )}
          </div>
        </main>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
