import React from "react";
import { Bnb, Usdc, Cake } from "react-web3-icons";
import JttIcon from "../assets/images/jtt-token.png";
import PtcIcon from "../assets/images/partner_logs/ptc_logo.png";

export default function Icon(props) {
  const { symbol } = props;
  switch (symbol) {
    case "WBNB":
      return <Bnb className="text-3xl" />;
    case "USDC":
      return <Usdc className="text-3xl" />;
    case "Cake":
      return <Cake className="text-3xl" />;
    case "JTT":
      return <img src={JttIcon} className="h-8" alt="Justus logo" />;
    case "PTC":
      return <img src={PtcIcon} className="h-8" alt="PTC logo" />;
    default:
      break;
  }
}
