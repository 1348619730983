export const BNBAnchorLinks = {
    twitter: "https://twitter.com/BNBCHAIN",
    website: "https://www.binance.com/en/bnb",
    medium: "https://medium.com/@binance",
    telegram: "https://t.me/bnbchain",
    youtube: "https://www.youtube.com/c/BNBChainOfficial",
    discord: "https://discord.com/invite/bnbchain",
  };

  export const USDCAnchorLinks = {
    twitter: "https://twitter.com/circle",
    website: "https://www.circle.com/blog/ushering-in-the-next-chapter-for-usdc",
    medium: "https://medium.com/@binance", // Need to find their Medium 
    telegram: "https://t.me/bnbchain", // need to find their telegram
    youtube: "https://www.youtube.com/c/Circlecryptofinance",
    discord: "https://discord.com/invite/buildoncircle",
  };

  export const CAKEAnchorLinks = {
    twitter: "https://twitter.com/pancakeswap",
    website: "https://pancakeswap.finance/",
    medium: "https://medium.com/pancakeswap",
    telegram: "https://t.me/PancakeSwap",
    youtube: "https://www.youtube.com/c/BNBChainOfficial", // Don't have youtube, need to find a condition to account for this
    discord: "https://discord.com/invite/pancakeswap",
  };

  export const JTTAnchorLinks = {
    twitter: "https://twitter.com/JustusTokenX",
    website: "https://justustoken.com/",
    medium: "https://medium.com/@JustusToken",
    telegram: "https://t.me/justustoken",
    youtube: "https://www.youtube.com/@Justus-Token",
    discord: "https://discord.gg/justustoken",
  };

  export const PTCAnchorLinks = {
    twitter: "https://twitter.com/PTCToken",
    website: "http://www.ptctoken.io/",
    medium: "https://medium.com/@JustusToken", //We dont currently have it
    telegram: "https://t.me/PTCToken",
    youtube: "https://www.youtube.com/@PTCTokenOfficial/",
    discord: "https://discord.com/invite/Nvs7wFXYCz",
  };