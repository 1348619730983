import { Button } from "@material-tailwind/react";
import React from "react";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { CAKEAnchorLinks } from "../constants/links/index";

export default function PartnerModalCake(props) {
  const { showModal, setShowModal } = props;
  const handleClick = () => {
    window.open('https://justustoken.com/whale-fund.html', '_blank');
  };
  return (
    <>
      {showModal ? (
        <div className="bg-[#231a4f]">
          <div className="justify-center w-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto sm:max-w-3xl flex flex-row justify-center">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#231a4f] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl font-semibold">Cake</h3>
                  <span
                    className="bg-transparent text-4xl block outline-none focus:outline-none cursor-pointer"
                    onClick={() => setShowModal(false)}
                  >
                    {String.fromCharCode(215)}
                  </span>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                  <p className="my-4 text-sm sm:text-base md:text-lg leading-relaxed">
                  The whale fund's impact on our community has been transformative. By reinvesting profits into the community, it distributes crypto CAKE rewards sourced from V3 farming, with 33% dedicated monthly to stakers. This approach fosters a thriving ecosystem, encouraging active community engagement and equitable rewards.                
                  </p>

                  <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl">Pool Rewards Distribution Breakdown:</p>
                    <ul className="list-disc list-inside">
                      <li className="ml-4"> 
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">1/3 of the Rewards for JTT Stakers</span>
                      </li>
                      <li className="ml-4">
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">2/3 of the Rewards for LP Stakers</span>
                      </li>
                    </ul>
                  <p className="italic text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl mt-4">
                    Stake Now!
                  </p>

                  <p className="text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl">Learn more about the whale fund here</p>

                  <Button onClick={handleClick} className="border-solid hover:bg-neutral-100 border-2 px-8 border-sky-500	text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl block cursor-pointer padding-2">
                    Whale Fund
                  </Button>
                </div>
                {/*footer*/}
                <div className="flex flex-row items-center justify-center p-3 border-t border-solid border-slate-200 rounded-b">
                <div className="flex flex-row gap-3 mb-1 text-2xl">
                    <a href={CAKEAnchorLinks.twitter} target="_blank" rel="noreferrer">
                      <FaSquareTwitter className="cursor-pointer" />
                    </a>
                    <a href={CAKEAnchorLinks.website} target="_blank" rel="noreferrer">
                      <FaGlobe className="cursor-pointer" />
                    </a>
                    <a href={CAKEAnchorLinks.medium} target="_blank" rel="noreferrer">
                      <FaMedium className="cursor-pointer" />
                    </a>
                    <a href={CAKEAnchorLinks.telegram} target="_blank" rel="noreferrer">
                      <FaTelegram className="cursor-pointer" />
                    </a>
                    <a href={CAKEAnchorLinks.discord} target="_blank" rel="noreferrer">
                      <FaDiscord className="cursor-pointer" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}
