import React from "react";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { USDCAnchorLinks } from "../constants/links/index";

export default function PartnerModalUsdc(props) {
  const { showModal, setShowModal } = props;
  return (
    <>
      {showModal ? (
        <div className="bg-[#231a4f]">
          <div className="justify-center w-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto sm:max-w-3xl flex flex-row justify-center">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#231a4f] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl font-semibold">USDC</h3>
                  <span
                    className="bg-transparent text-4xl block outline-none focus:outline-none cursor-pointer"
                    onClick={() => setShowModal(false)}
                  >
                    {String.fromCharCode(215)}
                  </span>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                  <p className="my-4 text-sm sm:text-base md:text-lg leading-relaxed">
                    To celebrate the launch of the Justus Staking Platform, we are excited to announce a special staking reward of USDC for the first month! Stake JTT now to claim your share.
                  </p>
                  
                  <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl">Pool Rewards Distribution Breakdown:</p>
                    <ul className="list-disc list-inside">
                      <li className="ml-4"> 
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">1/3 of the Rewards for JTT Stakers</span>
                      </li>
                      <li className="ml-4">
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">2/3 of the Rewards for LP Stakers</span>
                      </li>
                    </ul>
                    <p className="mt-4 text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl">
                      Don't miss out on this limited-time opportunity to earn USDC rewards with us!
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl mt-4">
                      Stake Now!
                    </p>
                </div>
                {/*footer*/}
                <div className="flex flex-row items-center justify-center p-3 border-t border-solid border-slate-200 rounded-b">
                <div className="flex flex-row gap-3 mb-1 text-2xl">
                    <a href={USDCAnchorLinks.twitter} target="_blank" rel="noreferrer">
                      <FaSquareTwitter className="cursor-pointer" />
                    </a>
                    <a href={USDCAnchorLinks.website} target="_blank" rel="noreferrer">
                      <FaGlobe className="cursor-pointer" />
                    </a>
                    <a href={USDCAnchorLinks.youtube} target="_blank" rel="noreferrer">
                      <FaYoutube className="cursor-pointer" />
                    </a>
                    <a href={USDCAnchorLinks.discord} target="_blank" rel="noreferrer">
                      <FaDiscord className="cursor-pointer" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}
