import React from "react";
import partnership from "../../assets/images/partner_logs/partnership-new.webp";

export default function Partner(props) {
  const { text, description } = props;

return (
  <div className="bg-[#376eab] rounded-xl flex flex-col justify-center items-center h-full py-10 pb-0 pt-3 m-0">
    <h3 className="text-xl font-semibold text-center pt-0 m-0">{text}</h3>

    <div className="relative p-2 flex-auto text-center">
      <img
        src={partnership}
        alt="your_alt_text"
        className="my-3 max-w-customRem mx-auto"
      />
      <p className="text-sm leading-relaxed m-0">{description}</p>
      <a href="mailto:support@justustoken.com" className="text-blue-500 hover:underline">
        <p className="pt-4 pb-0 m-0">
        support@justustoken.com
        </p>
      </a>
    </div>
  </div>
  );
}
