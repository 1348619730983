import React, { useState } from "react";
import PartnerCard from "./partner_card";
import BNBCard from "./bnb_card";
import CommunityCard from "./community_card";
import CakeCard from "./cake_card";
import USDCCard from "./usdc_card";
import PTCCard from "./ptc_card";
import PartnerModalBnb from "../../components/modalBnb";
import PartnerModalUsdc from "../../components/modalUsdc";
import PartnerModalCake from "../../components/modalCake";
import PartnerModalJtt from "../../components/modalJtt";
import PartnerModalPTC from "../../components/modalPTC";

import BnbImage from "../../assets/images/partner_logs/bnb-logo.svg";
import UsdcImage from "../../assets/images/partner_logs/usdc-logo.svg";
import CakeImage from "../../assets/images/partner_logs/cake-logo.svg";
import JttImage from "../../assets/images/partner_logs/jtt-token.png";
import PtcImage from "../../assets/images/partner_logs/ptc_logo.png";

import {
  BNB_ADDRESS,
  USDC_ADDRESS,
  CAKE_ADDRESS,
  JTT_ADDRESS,
  PTC_ADDRESS,
} from "../../constants/address";

import {
  BNB_DECIMAL,
  USDC_DECIMAL,
  CAKE_DECIMAL,
  JTT_DECIMAL,  
  PTC_DECIMAL
} from "../../constants/decimal";

export default function PartnerComponent() {
  const [showBNBModal, setBNBShowModal] = useState(false);
  const [showUSDCModal, setUSDCShowModal] = useState(false);
  const [showCAKEModal, setCAKEShowModal] = useState(false);
  const [showJTTModal, setJTTShowModal] = useState(false);
  const [showPTCModal, setPTCShowModal] = useState(false);

  return (
    <div className="w-full flex flex-col p-5  pt-10">
      <p className="text-xl mb-5 font-semibold">Partners</p>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
        <BNBCard
          setShowModal={setBNBShowModal}
          image={BnbImage}
          tokenAddr={BNB_ADDRESS}
          text="BNB"
          description="To celebrate the launch of the Justus Staking Platform, we are excited to announce a special giveaway of BNB for the first month! Simply stake now to claim your share. 

          Distribution Breakdown:
          - 1/3 for JTT Staking
          - 2/3 for LP Staking

          Don't miss out on this limited-time opportunity to earn BNB rewards with us!

          Stake Now!"
          decimal={BNB_DECIMAL}
        />
        <USDCCard
          setShowModal={setUSDCShowModal}
          image={UsdcImage}
          tokenAddr={USDC_ADDRESS}
          text="USDC"
          description="To celebrate the launch of the Justus Staking Platform, we are excited to announce a special giveaway of USDC for the first month! Simply stake now to claim your share. 

          Distribution Breakdown:
          - 1/3 for JTT Staking
          - 2/3 for LP Staking

          Don't miss out on this limited-time opportunity to earn USDC rewards with us!

          Stake Now!"
          decimal={USDC_DECIMAL}
        />
        <CakeCard
          setShowModal={setCAKEShowModal}
          image={CakeImage}
          tokenAddr={CAKE_ADDRESS}
          text="CAKE"
          description="The impact of the generous whale fund on our community has been nothing short of transformative. 
          By channeling its profits from invested capital into the community, the whale fund has facilitated the distribution of crypto CAKE rewards sourced 
          from V3 farming rewards. Notably, a significant portion, precisely 33%, of the farming rewards is dedicated monthly to stakers, ensuring a 
          sustainable and equitable distribution mechanism. This proactive investment approach has effectively amplified the benefits of the CAKE rewards, 
          nurturing a flourishing ecosystem where community members can actively participate and reap the rewards of their engagement.
          
          Learn more about the whale fund here"
          decimal={CAKE_DECIMAL}
        />
        <PartnerCard
          setShowModal={setJTTShowModal}
          image={JttImage}
          tokenAddr={JTT_ADDRESS}
          text="JTT"
          description="Justus, an innovative blockchain platform, is reshaping decentralized finance. 
          Through its incentive program, users staking Justus tokens can earn JTT and partner tokens. 
          Justus rewards stem from 0.5% of the 3% buy and sell tax, ensuring a sustainable and enticing reward system for the community."
          decimal={JTT_DECIMAL}
        />
        <PTCCard
          setShowModal={setPTCShowModal}
          image={PtcImage}
          tokenAddr={PTC_ADDRESS}
          text="PTC"
          description="Prosperity Truth Coalition is a project dedicated to helping rug and scam victims to slowly and safely recoup losses. PTC Token is an Bep20 Token on Binance Smart Chain (BSC) that offers four utilities that are beneficial to its holders. These utilities include Reflections (base), the Burn Portal, The Staking portal, and the dApp Trading Portal. With a total tax of 1% (0.5% Dev and 0.5% Reflections) on buys, sells and Transfers, holding PTC allows you to benefit from reflections as the base utility to get more PTC tokens from each transfer that is done. If you stake PTC using our Proof Of Holding Consensus model, you will receive Binance Pegged XRP tokens as rewards with a 30-day staking duration while still retaining full custody of your tokens in your wallet during the staking term."
          decimal={PTC_DECIMAL}
        />
        <CommunityCard
          setShowModal={setJTTShowModal}
          image={JttImage}
          tokenAddr={JTT_ADDRESS}
          description="Contact us to explore how our mutual partnership can benefit both our projects and communities through tokens featured on our staking platform."
          text="Interested in becoming a Justus Partner?"
          decimal={JTT_DECIMAL}
        />
      </div>
      {/* <PartnerModal showModal={showModal} setShowModal={setShowModal} /> */}
      <PartnerModalBnb
        showModal={showBNBModal}
        setShowModal={setBNBShowModal}
      />
      <PartnerModalUsdc
        showModal={showUSDCModal}
        setShowModal={setUSDCShowModal}
      />
      <PartnerModalCake
        showModal={showCAKEModal}
        setShowModal={setCAKEShowModal}
      />
      <PartnerModalJtt
        showModal={showJTTModal}
        setShowModal={setJTTShowModal}
      />
      <PartnerModalPTC
        showModal={showPTCModal}
        setShowModal={setPTCShowModal}
      />
    </div>
  );
}
