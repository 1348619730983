import React from "react";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { PTCAnchorLinks } from "../constants/links/index";

export default function PartnerModalBnb(props) {
  const { showModal, setShowModal } = props;
  return (
    <>
      {showModal ? (
        <div className="bg-[#231a4f]">
          <div className="justify-center  w-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto sm:max-w-3xl flex flex-row justify-center">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-[#231a4f] outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl font-semibold">PTC</h3>
                  <span
                    className="bg-transparent text-4xl block outline-none focus:outline-none cursor-pointer"
                    onClick={() => setShowModal(false)}
                  >
                    {String.fromCharCode(215)}
                  </span>
                </div>
                {/*body*/}
                <div className="relative p-3 flex-auto">
                  <p className="my-4 text-sm sm:text-base md:text-lg leading-relaxed">
                  Prosperity Truth Coalition is a project dedicated to helping rug and scam victims to slowly and safely recoup losses. PTC Token is an Bep20 Token on Binance Smart Chain (BSC) that offers four utilities that are beneficial to its holders. These utilities include Reflections (base), the Burn Portal, The Staking portal, and the dApp Trading Portal. With a total tax of 1% (0.5% Dev and 0.5% Reflections) on buys, sells and Transfers, holding PTC allows you to benefit from reflections as the base utility to get more PTC tokens from each transfer that is done. If you stake PTC using our Proof Of Holding Consensus model, you will receive Binance Pegged XRP tokens as rewards with a 30-day staking duration while still retaining full custody of your tokens in your wallet during the staking term.
                  </p>
                  <p className="font-bold text-base sm:text-lg md:text-xl lg:text-1xl xl:text-2xl mt-4">Distribution to start on the 10th of December</p>
                    <ul className="list-disc list-inside">
                      <li className="ml-4"> 
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">Claim Day: 14th of the month starting at 12pm est. Lasts 24h</span>
                      </li>
                      <li className="ml-4">
                        <span className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl font-bold">Staking Day: 15th of the month starting at 12pm est. Lasts 24h</span>
                      </li>
                    </ul>
                    <p className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl mt-4">
                      Earn Bep20XRP for staking PTC!
                    </p>
                    <p className="italic text-sm sm:text-base md:text-lg lg:text-xl xl:text-1xl mt-4">
                      Stake Now!
                    </p>
                </div>

                {/*footer*/}
                <div className="flex flex-row items-center justify-center p-3 border-t border-solid border-slate-200 rounded-b">
                  <div className="flex flex-row gap-3 mb-1 text-2xl">
                    <a href={PTCAnchorLinks.twitter} target="_blank" rel="noreferrer">
                      <FaSquareTwitter className="cursor-pointer" />
                    </a>
                    <a href={PTCAnchorLinks.website} target="_blank" rel="noreferrer">
                      <FaGlobe className="cursor-pointer" />
                    </a>
                    <a href={PTCAnchorLinks.telegram} target="_blank" rel="noreferrer">
                      <FaTelegram className="cursor-pointer" />
                    </a>
                    <a href={PTCAnchorLinks.youtube} target="_blank" rel="noreferrer">
                      <FaYoutube className="cursor-pointer" />
                    </a>
                    <a href={PTCAnchorLinks.discord} target="_blank" rel="noreferrer">
                      <FaDiscord className="cursor-pointer" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </>
  );
}
