import React, { useState } from "react";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaGlobe } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { useContractReads } from "wagmi";
import { ethers } from "ethers";
import {
  JustusStakingContractAddr,
  LPStakingContractAddr,
} from "../../constants/address";
import { StakingContractABI } from "../../constants/abis";
import { showTokenBalance, showDateFormatted } from "../../utils";
import { BNBAnchorLinks } from "../../constants/links/index";
import { CAKEAnchorLinks } from "../../constants/links/index";
import { USDCAnchorLinks } from "../../constants/links/index";
import { JTTAnchorLinks } from "../../constants/links/index";
import { PTCAnchorLinks } from "../../constants/links/index";

export default function Partner(props) {
  const { image, tokenAddr, setShowModal, text, description, decimal } = props;
  const [loaded, setLoaded] = useState(false);
  const currentTimeStamp = Date.now();
  const [jttRestReward, setJttRestReward] = useState(0);
  const [lpRestReward, setLPRestReward] = useState(0);

  // Create an object to map text values to their respective anchor lists
  const anchorLists = {
    BNB: BNBAnchorLinks,
    USDC: USDCAnchorLinks,
    CAKE: CAKEAnchorLinks,    
    JTT: JTTAnchorLinks,
    PTC: PTCAnchorLinks,
    // Add more as needed
  };

  // Assuming 'text' has a value of either 'BNB' or 'CAKE'
  const selectedAnchorLinks = anchorLists[text];


  const { data: rewardData } = useContractReads({
    enabled: true,
    watch: true,
    contracts: [
      {
        address: JustusStakingContractAddr,
        abi: StakingContractABI,
        functionName: "rewardData",
        args: [tokenAddr],
      },
      {
        address: LPStakingContractAddr,
        abi: StakingContractABI,
        functionName: "rewardData",
        args: [tokenAddr],
      },
    ],
    onSuccess() {
      const jttRewardData = rewardData[0].result;
      const jttRewardRate = ethers.utils.formatUnits(
        jttRewardData[3].toString(),
        decimal
      );
      const jttRewardExitTime =
        ethers.utils.formatUnits(jttRewardData[2].toString(), 0) * 1000;
      if (jttRewardExitTime > currentTimeStamp) {
        const restJttReward =
          (jttRewardRate * (jttRewardExitTime - currentTimeStamp)) / 1000;
        setJttRestReward(restJttReward);
      }

      const lpRewardData = rewardData[1].result;
      const lpRewardRate = ethers.utils.formatUnits(
        lpRewardData[3].toString(),
        decimal
      );
      const lpRewardExitTime =
        ethers.utils.formatUnits(lpRewardData[2].toString(), 0) * 1000;
      if (lpRewardExitTime > currentTimeStamp) {
        const restLpReward =
          (lpRewardRate * (lpRewardExitTime - currentTimeStamp)) / 1000;
        setLPRestReward(restLpReward);
      }
    },
  });
  return (
    <div className="bg-[#376eab] rounded-xl flex flex-col">
      <div className="flex flex-col sm:flex-row items-center sm:items-start p-5 w-full">
        <div
          className={
            !loaded ? "relative sm:mr-3 w-48 pt-45 " : "relative sm:mr-3 w-48"
          }
        >
          <img
            src={image}
            className="w-full"
            onLoad={() => setLoaded({ loaded: true })} alt="USDC logo"
          />
          {!loaded && (
            <div className="h-0 pb-[100%] w-full image-thumbnail rounded-lg bg-gray animate-pulse flex items-center justify-center absolute top-0">
              <span className="pt-[100%]">{text}</span>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between">
            <p className="font-medium mb-5  mt-5 sm:mt-0 ">{text}</p>
          </div>

        <div className="flex flex-row gap-2 mb-5 text-lg w-full">
            <a href={selectedAnchorLinks.twitter} target="_blank" rel="noreferrer">
              <FaSquareTwitter className="cursor-pointer" />
            </a>
            <a href={selectedAnchorLinks.website} target="_blank" rel="noreferrer">
              <FaGlobe className="cursor-pointer" />
            </a>
            <a href={selectedAnchorLinks.telegram} target="_blank" rel="noreferrer">
              <FaTelegram className="cursor-pointer" />
            </a>
            <a href={selectedAnchorLinks.youtube} target="_blank" rel="noreferrer">
              <FaYoutube className="cursor-pointer" />
            </a>
            <a href={selectedAnchorLinks.discord} target="_blank" rel="noreferrer">
              <FaDiscord className="cursor-pointer" />
            </a>
          </div>

          <div className="grid">
            <span className="line-clamp-3 break-words text-sm">
              {description}
            </span>
            <div className="flex justify-end">
              <span
                className="text-blue-300 cursor-pointer inline-block"
                onClick={() => setShowModal(true)}
              >
                Read More
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grow block bg-[#046bd3] rounded-b-xl">
        <div className="flex flex-row justify-between p-5  text-sm">
          <div>
            <p>Total to distribute:</p>
            <p className="text-md">
              JTT Staking : {showTokenBalance(jttRestReward, 0, true)} {text}
            </p>
            <p className="text-md">
              LP Staking : {showTokenBalance(lpRestReward, 0, true)} {text}
            </p>
          </div>
          <div>
            <p>Distribution ended</p>
            <p>Big thank you to the PTC team</p>
          </div>
        </div>
      </div>
    </div>
  );
}
