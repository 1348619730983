import React, { useEffect, useState } from "react";
import StakedAmountCard from "../stake/staked_amount_card";
import IconTotalStakedToken from "../../assets/images/icons/total-token-staked.png";
import ChartComponent from "../../components/chart";
import IconTotalTokensTVL from "../../assets/images/icons/IconTotalTokenSTVL.png";
import IconTotalLiquidity from "../../assets/images/icons/IconTotalLiquidity.png";
import IconMarketCap from "../../assets/images/icons/market-cap.png";
import IconLPTVL from "../../assets/images/icons/IconLPTVL.png";
import IconBNBJustusPrice from "../../assets/images/icons/IconETHJustusPrice.png";
import IconTotalJustusTVL from "../../assets/images/icons/IconTotalJustusTVL.png";
import IconTotalLPStake from "../../assets/images/icons/IconTotalLPStake.png";
import IconTotalStakePercent from "../../assets/images/icons/IconTotalStakePercent.png";
import IconJustusStakeAPY from "../../assets/images/icons/IconJustusStakeAPY.png";
import IconLPStakingAPY from "../../assets/images/icons/IconLPStakingAPY.png";
import { ids } from "../../constants/ids";
import { useAPYValues } from '../../utils/APYValues';

import {
  useContractRead,
} from "wagmi";
import axios from "axios";
import { BigNumber, ethers } from "ethers";
import {
  JustusStakingContractAddr,
  JustusTokenAddr,
  JustusTokenAddrBNB,
  LPTokenAddr,
  LPStakingContractAddr,
} from "../../constants/address";
import { StakingContractABI, tokenABIJtt } from "../../constants/abis";

export default function Dashboard() {
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [lpPrice, setLPPrice] = useState();
  const [totalLiquidity, setTotalLiquidity] = useState();
  const [martetCap, setMarketCap] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [tvl, setTvl] = useState(0);
  const [lpTvl, setLPTvl] = useState(0);
  const [totalTokenStaked, setTotalTokenStaked] = useState(0);
  const [stakeTokenPercent, setStakedTokenPercent] = useState(0);
  const [stakeLPPercent, setStakedLPPercent] = useState(0);
  const { jttAPY, lpAPY } = useAPYValues();
 
  useEffect(() => {
    axios
      .get(
        "https://api.dexscreener.com/latest/dex/tokens/" + JustusTokenAddr
      )
      .then((response) => {
        setTotalLiquidity(response.data?.pairs[0].liquidity?.usd);
      });
    axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`
      )
      .then((response) => {
        setTokenPrice(response.data?.justus.usd.toFixed(7));
      });
    setIsLoading(false);
  }, []);

  const { data: totalSupply } = useContractRead({
    enabled: true,
    address: JustusTokenAddrBNB,
    abi: tokenABIJtt,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      setMarketCap(
        ethers.utils.formatEther(totalSupply) * parseFloat(tokenPrice)
      );
    },
  });

  const { data: totalStakedBalance } = useContractRead({
    enabled: true,
    address: JustusStakingContractAddr,
    abi: StakingContractABI,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      setTotalTokenStaked(totalStakedBalance);
    },
  });

  const { data: lockedTokenAmount } = useContractRead({
    enabled: true,
    address: JustusTokenAddr,
    abi: tokenABIJtt,
    functionName: "balanceOf",
    args: [LPTokenAddr],
    watch: true,
    onSuccess() {
      setTvl(
        ethers.utils.formatEther(
          BigNumber.from(totalTokenStaked).add(
            BigNumber.from(lockedTokenAmount)
          )
        ) * parseFloat(tokenPrice)
      );
    },
  });

  const { data: supplyOfJustus } = useContractRead({
    enabled: true,
    address: JustusTokenAddr,
    abi: tokenABIJtt,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      const stakingPercent =
        (ethers.utils.formatEther(totalStakedBalance) /
          ethers.utils.formatEther(supplyOfJustus)) *
        100;
      setStakedTokenPercent(stakingPercent);
    },
  });

  const { data: totalLPSupply } = useContractRead({
    enabled: true,
    address: LPTokenAddr,
    abi: tokenABIJtt,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      const LPPrice = totalLiquidity / ethers.utils.formatEther(totalLPSupply);
      setLPPrice(LPPrice);
    },
  });

  const { data: totalStakedLPBalance } = useContractRead({
    enabled: true,
    address: LPStakingContractAddr,
    abi: StakingContractABI,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      setLPTvl(
        ethers.utils.formatEther(totalStakedLPBalance) * parseFloat(lpPrice)
      );
    },
  });

  const { data: supplyOfLP } = useContractRead({
    enabled: true,
    address: LPTokenAddr,
    abi: tokenABIJtt,
    functionName: "totalSupply",
    watch: true,
    onSuccess() {
      const stakingPercent =
        (ethers.utils.formatEther(totalStakedLPBalance) /
          ethers.utils.formatEther(supplyOfLP)) *
        100;
      setStakedLPPercent(stakingPercent);
    },
  });

  return (
    <div className="p-5 pt-10">
      <p className="text-xl mb-5 font-semibold">Dashboard</p>
      <div className="grid auto-cols-fr sm:grid-cols-3">
        <StakedAmountCard
          icon={IconTotalLiquidity}
          classNames={"bg-[#fa6805] mb-0 sm:mb-5"}
          text="Total Liquidity"
          isFormated={true}
          balance={totalLiquidity}
          loading={isLoading}
        />
        <StakedAmountCard
          icon={IconMarketCap}
          classNames={"bg-[#0e6ba0] mb-0 sm:mb-5"}
          text="Fully Diluted Market Cap"
          balance={martetCap}
          isFormated={true}
          loading={isLoading}
        />
        <StakedAmountCard
          icon={IconTotalTokensTVL}
          classNames={"bg-[#fdba0d] mb-0 sm:mb-5"}
          text="Total Tokens TVL"
          balance={tvl}
          loading={isLoading}
          isFormated={true}
          isCurrency={true}
        />
      </div>
      <ChartComponent />
      <div className="flex flex-col md:flex-row md:items-between gap-3">
        <div className="native-stake w-full md:w-1/2 text-sm">
          <div className="bg-[#376eab] w-full text-center p-8 rounded-xl font-semibold">
            Justus Tokens
          </div>
          <div className="flex flex-col md:flex-row mt-3 gap-3 flex-1">
            <StakedAmountCard
              icon={IconTotalStakedToken}
              text={"Total JTT Staked"}
              balance={totalTokenStaked}
              isJtt={true}
              loading={isLoading}
              classNames="mb-3" // Consistent spacing
            />
            <StakedAmountCard
              icon={IconTotalStakePercent}
              text={"Total % of JTT Staked"}
              balance={stakeTokenPercent}
              loading={isLoading}
              isCurrency={false}
              isFormated={true}
              classNames="mb-3" // Consistent spacing
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 ">
            <StakedAmountCard
              icon={IconBNBJustusPrice}
              text={"JTT Price"}
              balance={tokenPrice}
              classNames="mb-3" // Consistent spacing
              isFormated={true}
              isPrice={true}
            />
            <StakedAmountCard
              icon={IconTotalJustusTVL}
              text={"Total JTT TVL"}
              balance={tvl}
              classNames="mb-3" // Consistent spacing
              loading={isLoading}
              isFormated={true}
            />
          </div>
        </div>
        <div className="native-stake w-full md:w-1/2 text-sm flex flex-col">
          <div className="bg-[#376eab] w-full text-center p-8 rounded-xl font-semibold">
            LP Tokens
          </div>
          <div className="flex flex-col md:flex-row mt-3 gap-3 flex-1">
            <StakedAmountCard
              icon={IconTotalLPStake}
              text={"Total LP Staked"}
              balance={totalStakedLPBalance}
              classNames="mb-3 justify-center" // Consistent spacing
              loading={isLoading}
              isCurrency={false}
              isJtt={false}
              isLP={true}
            />
            <StakedAmountCard
              icon={IconTotalStakePercent}
              text={"Total % of LP Staked"}
              balance={stakeLPPercent}
              loading={isLoading}
              classNames="mb-3" // Consistent spacing
              isFormated={true}
              isCurrency={false}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-3 flex-1">
            <StakedAmountCard
              icon={IconBNBJustusPrice}
              text={"LP Price"}
              balance={lpPrice}
              classNames="mb-3" // Consistent spacing
              loading={isLoading}
              isFormated={true}
            />
            <StakedAmountCard
              icon={IconLPTVL}
              text={"Total LP TVL"}
              balance={lpTvl}
              classNames="mb-3 justify-center" // Consistent spacing
              loading={isLoading}
              isFormated={true}
              isCurrency={true}
            />
          </div>
        </div>
      </div>

      <div className="bg-[#376eab] w-[100%] p-5  rounded-xl mt-3">
        <div className="flex flex-row items-center  w-[100%] text-center">
          <div className="flex flex-row justify-center items-center w-[100%] text-center">
            <img src={IconTotalStakedToken} className="h-10 w-10" alt="Total Staked Icon"/>
            <div className="pt-1 ml-2 font-semibold">
              <div className="text-xs py-0 flex flex-row items-center text-left gap-1">
                {"Staking APR"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <StakedAmountCard
          icon={IconJustusStakeAPY}
          text={"JTT Staking APR"}
          balance={jttAPY}
          classNames="mt-3 bg-[#fa6805]"
          loading={isLoading}
          isFormated={true}
          isCurrency={false}
        />
        <StakedAmountCard
          icon={IconLPStakingAPY}
          text={"LP Staking APR"}
          balance={lpAPY}
          classNames="mt-3 bg-[#fdba0d]"
          loading={isLoading}
          isFormated={true}
          isCurrency={false}
        />
      </div>
    </div>
  );
}
