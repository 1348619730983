export const BNB_DECIMAL = 18;
export const USDC_DECIMAL = 18;
export const CAKE_DECIMAL = 18;
export const JTT_DECIMAL = 18; // Add new tokens to the end, they must be in order.
export const PTC_DECIMAL = 8;

export const Decimals = [
  18, // USDT , mumbai
  18, // USDC , mumbai
  18, // JTT  , mumbai
  18,
  8, //PTC
];
