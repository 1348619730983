import { useState, useEffect } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import {
  JustusStakingContractAddr,
  JustusTokenAddr,
  LPTokenAddr,
  LPStakingContractAddr,
  RewardTokenAddr2,
} from '../constants/address';
import { StakingContractABI, tokenABIJtt } from '../constants/abis';
import { Decimals } from '../constants/decimal';
import { useContractRead, useContractReads } from 'wagmi';

const YEAR_IN_SECONDS = 60 * 60 * 24 * 365;

export const useAPYValues = (initialValue = 0) => {
  const [jttAPY, setJTTAPY] = useState(initialValue);
  const [lpAPY, setLPAPY] = useState(initialValue);
  const [prices, setPrices] = useState([]);
  const [totalLiquidity, setTotalLiquidity] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTokenData = async () => {
      try {
        const totalLiquidityResponse = await axios.get(
          `https://api.dexscreener.com/latest/dex/tokens/${JustusTokenAddr}`
        );
        const liquidityData = totalLiquidityResponse.data?.pairs[0];
        setTotalLiquidity(liquidityData.liquidity.usd);
        setTokenPrice(liquidityData.priceUsd);

        const pricePromises = RewardTokenAddr2.map(async (address) => {
          const response = await axios.get(
            `https://api.dexscreener.com/latest/dex/tokens/${address}`
          );
          return response.data?.pairs[0].priceUsd;
        });

        const prices = await Promise.all(pricePromises);
        setPrices(prices);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchTokenData();
  }, []);

  const { data: totalStakedBalance } = useContractRead({
    enabled: true,
    address: JustusStakingContractAddr,
    abi: StakingContractABI,
    functionName: 'totalSupply',
    watch: true,
  });

  const { data: totalLPSupply } = useContractRead({
    enabled: true,
    address: LPTokenAddr,
    abi: tokenABIJtt,
    functionName: 'totalSupply',
    watch: true,
  });

  const { data: totalStakedLPBalance } = useContractRead({
    enabled: true,
    address: LPStakingContractAddr,
    abi: StakingContractABI,
    functionName: 'totalSupply',
    watch: true,
  });

  const { data: totalJTTinStakingContract } = useContractRead({
    enabled: true,
    address: JustusTokenAddr,
    abi: tokenABIJtt,
    functionName: 'balanceOf',
    args: [JustusStakingContractAddr],
    watch: true,
  });

  const { data: rewardDurationsForLP } = useContractReads({
    enabled: true,
    watch: true,
    contracts: RewardTokenAddr2.map((addr) => ({
      address: LPStakingContractAddr,
      abi: StakingContractABI,
      functionName: 'rewardData',
      args: [addr],
    })),
  });

  const { data: rewardTokensBalanceForLP } = useContractReads({
    enabled: true,
    watch: true,
    contracts: RewardTokenAddr2.map((addr) => ({
      address: addr,
      abi: tokenABIJtt,
      functionName: 'balanceOf',
      args: [LPStakingContractAddr],
    })),
    onSuccess(data) {
      console.log('LP Staking Data:', data);
      let totalLPAPY = 0;
      data.forEach((rewardTokenBalance, index) => {
        const balance = ethers.utils.formatUnits(
          rewardTokenBalance.result,
          Decimals[index]
        );
        const price = prices[index];
        const stakedLPBalance = ethers.utils.formatEther(totalStakedLPBalance);
        const lpSupply = ethers.utils.formatEther(totalLPSupply);
        const liquidity = totalLiquidity;
        
        // Sanity check for price values
        if (price > 1000000) {
          console.warn(`Price for LP [${index}] is unusually high:`, price);
          return;
        }

        console.log(`Balance for LP [${index}]:`, balance);
        console.log(`Price for LP [${index}]:`, price);
        console.log(`Total Staked LP Balance:`, stakedLPBalance);
        console.log(`Total LP Supply:`, lpSupply);
        console.log(`Total Liquidity:`, liquidity);
        
        const r =
          ((balance * price) /
            (stakedLPBalance *
              parseFloat(
                liquidity / lpSupply
              ))) *
          100;
          
        const rewardDuration = rewardDurationsForLP[index].result[1];
        console.log(`Reward Duration for LP [${index}]:`, rewardDuration.toString());
        if (rewardDuration) {
          const oneYear = ethers.utils.parseEther(YEAR_IN_SECONDS.toString());
          const n = ethers.utils.formatEther(
            oneYear.div(ethers.BigNumber.from(rewardDuration))
          );
          console.log(`n for LP [${index}]:`, n);
          totalLPAPY += r * n;
          console.log(`r for LP [${index}]:`, r);
        }
      });
      console.log('Total LP APY:', totalLPAPY);
      setLPAPY(Math.round(totalLPAPY));
    },
  });

  const { data: rewardDurations } = useContractReads({
    enabled: true,
    watch: true,
    contracts: RewardTokenAddr2.map((addr) => ({
      address: JustusStakingContractAddr,
      abi: StakingContractABI,
      functionName: 'rewardData',
      args: [addr],
    })),
  });

  const { data: rewardTokensBalanceForJTT } = useContractReads({
    enabled: true,
    watch: true,
    contracts: RewardTokenAddr2.map((addr) => ({
      address: addr,
      abi: tokenABIJtt,
      functionName: 'balanceOf',
      args: [JustusStakingContractAddr],
    })),
    onSuccess(data) {
      console.log('JTT Staking Data:', data);
      let totalJTTAPY = 0;
      data.forEach((rewardTokenBalance, index) => {
        let balance;
        if (index === data.length - 1) {
          balance = ethers.utils.formatEther(
            ethers.BigNumber.from(totalJTTinStakingContract).sub(
              totalStakedBalance
            )
          );
        } else {
          balance = ethers.utils.formatUnits(
            rewardTokenBalance.result,
            Decimals[index]
          );
        }
        
        const price = prices[index];
        const stakedBalance = ethers.utils.formatEther(totalStakedBalance);
        const jttTokenPrice = parseFloat(tokenPrice);
        
        // Sanity check for price values
        if (price > 1000000) {
          console.warn(`Price for JTT [${index}] is unusually high:`, price);
          return;
        }

        console.log(`Balance for JTT [${index}]:`, balance);
        console.log(`Price for JTT [${index}]:`, price);
        console.log(`Total Staked Balance:`, stakedBalance);
        console.log(`JTT Token Price:`, jttTokenPrice);
        
        const jttRate =
          ((balance * price) /
            (stakedBalance * jttTokenPrice)) *
          100;
          
        console.log(`JTT Rate [${index}]:`, jttRate);
        const rewardDuration = rewardDurations[index].result[1];
        console.log(`Reward Duration for JTT [${index}]:`, rewardDuration.toString());
        if (rewardDuration > 0) {
          const oneYear = ethers.utils.parseEther(YEAR_IN_SECONDS.toString());
          const n = ethers.utils.formatEther(
            oneYear.div(ethers.BigNumber.from(rewardDuration))
          );
          console.log(`n for JTT [${index}]:`, n);
          totalJTTAPY += jttRate * n;
        }
      });
      console.log('Total JTT APY:', totalJTTAPY);
      setJTTAPY(Math.round(totalJTTAPY));
    },
  });

  return { jttAPY, lpAPY };
};
