export const JustusStakingContractAddr =
  "0xCC808545C74910572fdF17cdccEE399c2BC75BfD"; // BSC
export const JustusTokenAddr = "0xcdB3D3642FB4d48D2B5E4fb4a014448A2761C063"; //BSC
export const JustusTokenAddrBNB = "0xcdB3D3642FB4d48D2B5E4fb4a014448A2761C063"; //BSC

export const LPStakingContractAddr =
  "0x788BFBCee5C82E9426696343C41164da8037a859"; //BSC
export const LPTokenAddr = "0x5bc37Ae740Ec995360731f876E63085B1057c4B2"; //BSC

export const RewardTokenAddr = [
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", //WBNB
  "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d", //USDC
  "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82", // CAKE
  "0xcdB3D3642FB4d48D2B5E4fb4a014448A2761C063", // JTT
  "0xf382A28AAb0320e48D3D751f696f935B014B49BD", // PTC everytime we add a new address, we descrese the - value on line 171, on file APYValues.
];

export const RewardTokenAddr2 = [
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", //WBNB
  "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d", //USDC
  "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82", // CAKE
  "0xcdB3D3642FB4d48D2B5E4fb4a014448A2761C063", // JTT
];

export const BNB_ADDRESS = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; //BSC
export const CAKE_ADDRESS = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"; //BSC
export const USDC_ADDRESS = "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"; //BSC
export const JTT_ADDRESS = "0xcdB3D3642FB4d48D2B5E4fb4a014448A2761C063"; //BSC
export const PTC_ADDRESS = "0xf382A28AAb0320e48D3D751f696f935B014B49BD"; //PTC
